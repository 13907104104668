//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CloseIcon from '../../assets/close-icon.png'
import OpenIcon from '../../assets/logo-no-bg.svg'
import FileIcon from '../../assets/file.svg'
import CloseIconSvg from '../../assets/close.svg'
import ChatWindow from "../chat/ChatWindow.vue";
export default {
  name: "one_stop_trading",
  components:{
    ChatWindow
  },
  created(){
      this.currentModule = (location.hash.indexOf('/spotSpace') != -1) ? 'SpotSpace' : (location.hash.indexOf('/containerLeasing') != -1)? 'Leasing' : 'Trading';
      window.addEventListener('hashchange', this.hashChange)
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
      this.tradingMenuList = [
        { name: this.$t('t.settings_companyInfo'), path: '/profileInfo',hovered: false,img: require('../../assets/company_setting_icon.png'),hoverImg: require('../../assets/company_suspension_setting_icon.png')},
        { name: this.$t('t.settings_password'), path: '/passManage',hovered: false,img: require('../../assets/password_setting_icon.png'),hoverImg: require('../../assets/password_suspension_setting_icon.png')},
        { name: this.$t('t.settings_order'), path: '/orderManage',hovered: false,img: require('../../assets/order_setting_icon.png'),hoverImg: require('../../assets/order_suspension_setting_icon.png') },
        { name: this.$t('t.settings_delegation'), path: '/containerDelegation',hovered: false,img: require('../../assets/delegation_setting_icon.png'),hoverImg: require('../../assets/delegation_suspension_setting_icon.png') },
        { name: this.$t('t.settings_inquiry'), path: '/containerInquiry',hovered: false,img: require('../../assets/inquiry_setting_icon.png'),hoverImg: require('../../assets/inquiry_suspension_setting_icon.png') },
        { name: this.$t('t.settings_factoryOrder'), path: '/factoryOrderManage',hovered: false,img: require('../../assets/factory_setting_icon.png'),hoverImg: require('../../assets/factory_suspension_setting_icon.png') },
        { name: this.$t('t.settings_depot'), path: '/depotInfo',hovered: false,img: require('../../assets/depot_setting_icon.png'),hoverImg: require('../../assets/depot_suspension_setting_icon.png') },
        { name: this.$t('t.settings_demand'), path: '/demandInfo',hovered: false,img: require('../../assets/demand_setting_icon.png'),hoverImg: require('../../assets/demand_suspension_setting_icon.png') }
      ];
      this.menuList = [
          { name: this.$t('t.settings_companyInfo'), path: '/profileInfo',hovered: false,img: require('../../assets/company_setting_icon.png'),hoverImg: require('../../assets/company_suspension_setting_icon.png')  },
          { name: this.$t('t.settings_password'), path: '/passManage' ,hovered: false,img: require('../../assets/password_setting_icon.png'),hoverImg: require('../../assets/password_suspension_setting_icon.png')},
          { name: this.$t('t.settings_order'), path: '/spotSpaceOrder',hovered: false,img: require('../../assets/order_setting_icon.png'),hoverImg: require('../../assets/order_suspension_setting_icon.png') },
          { name: this.$t('t.settings_inquiry'), path: '/spotSpaceInquiryOrder',hovered: false,img: require('../../assets/inquiry_setting_icon.png'),hoverImg: require('../../assets/inquiry_suspension_setting_icon.png') }
      ];
      this.leasingMenuList = [
          { name: this.$t('t.settings_companyInfo'), path: '/profileInfo',hovered: false,img: require('../../assets/company_setting_icon.png'),hoverImg: require('../../assets/company_suspension_setting_icon.png')},
          { name: this.$t('t.settings_password'), path: '/passManage',hovered: false,img: require('../../assets/password_setting_icon.png'),hoverImg: require('../../assets/password_suspension_setting_icon.png')},
          { name: this.$t('t.settings_inquiry'), path: '/containerLeasingInquiryOrder',hovered: false,img: require('../../assets/inquiry_setting_icon.png'),hoverImg: require('../../assets/inquiry_suspension_setting_icon.png') }
      ];
      this.init();
      this.getTotalUnreadCount();
      this.showUmyNotice();
      this.getProfileInfo()
      this.currentVip = this.getLocalStoreItem('um-vip') === '1';
      this.showOverdueModule = this.getLocalStoreItem('um-vip') === '2';
  },
  data(){
    return{
      showTip: false,
      tipSubject: '',
      tipContent: '',
      showSetting: false,
      menuList: [],
      leasingMenuList: [],
      tradingMenuList: [],
      currentModule:'',
      showLogoutTip: false,
      DragEl: null, //拖动元素
      totalHeight: null,
      totalWidth: null,
      // 位置差
      disX: 0,
      disY: 0,
      drag: false,
      down: false,
      showChat: false,
      icons:{
        open:{
          img: OpenIcon,
          name: 'default',
        },
        close:{
          img: CloseIcon,
          name: 'default',
        },
        file:{
          img: FileIcon,
          name: 'default',
        },
        closeSvg:{
          img: CloseIconSvg,
          name: 'default',
        },
      },
      participants: [
        {
          id: 'user1',
          name: 'Matteo',
          imageUrl: 'https://avatars3.githubusercontent.com/u/1915989?s=230&v=4'
        },
        {
          id: 'user2',
          name: 'Support',
          imageUrl: 'https://avatars3.githubusercontent.com/u/37018832?s=200&v=4'
        }
      ], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
      titleImageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
      isChatOpen: true, // to determine whether the chat window should be open or closed
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      alwaysScrollToBottom: true, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true,
      showCloseButton: {
        type: Boolean,
        default: true
      },
      currentServiceModule: 'Trading',
      showDrag: true,
      socket: {},
      path: this.baseURL.replace('https', 'ws') + "/WebSocket/" + this.getLocalStoreItem("username"),
      heartbeat_timer: null, // 用于心跳检测
      active_close: false, // 是否主动关闭
      totalUnreadCount: 0,
      visible: true,
      hasChatOpened: false,
      showPremiumMember: false,
      showOverdueModule: false,
      showSettingModule: false,
      currentVip:  false,
      item:{
        companyContact: '',
        companyName: '',
        vipStartDate: '',
        vipEndDate: '',
        vipRemainingDays: ''
      }
    }
  },
  methods:{
    toAbout(){
      this.$router.push({
        path: '/about'
      })
    },
    hashChange(){
        this.currentModule = (location.hash.indexOf('/spotSpace') != -1) ? 'SpotSpace' : (location.hash.indexOf('/containerLeasing') != -1)? 'Leasing' : 'Trading';
    },
    closeSetting(){
        console.log('closeSetting')
        setTimeout(()=>{
            this.showSetting = false;
        }, 300)
    },
    handleClose() {
        this.showTip = false;
        this.$router.push('/containerDelegation')
    },
    navigatorPage (path) {
          let splits = window.location.href.split('#');
          if (path && splits.length >1 && splits[1] !== path ) {
              this.$router.push(path)
              this.showSetting = false
          }
      },
    handleButtonClick(){
      if(!this.drag){
          this.drag = false;
          this.down = false;
          this.showChat = true;
          this.showDrag = false;
      }
    },
    clickSetting(){
        this.showSetting = !this.showSetting;
    },
    toTrading(){
      this.currentModule = 'Trading'
      this.$router.push({
          path:'/containerTrading'
      })
    },
    toSpotSpace(){
      this.currentModule = 'SpotSpace'
      this.$router.push({
        path:'/spotSpace'
      })
    },
    toLeasing(){
        this.currentModule = 'Leasing'
        this.$router.push({
            path:'/containerLeasing'
        })
      },
    toLogin(){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
            path:'/login'
        })
    },
    toLogout(){
        this.showLogoutTip = true;
    },
    async logout(){
          const addResult = await this.$axios.post('/bk/auth/signout');
          if(addResult.message === 'You\'ve been signed out!'){
              this.showLogoutTip = false;
              localStorage.removeItem('token');
              localStorage.removeItem("username");
              localStorage.removeItem("userId");
              localStorage.removeItem("um-vip");
              localStorage.removeItem("depotVip");
              localStorage.removeItem("role");
              localStorage.removeItem("lastRefreshTime");
              let split = location.href.split('#');
              if (split.length > 1) {
                  if( this.currentModule === 'Trading'){
                      if (split[1] !== '/containerTrading') {
                          this.$router.push({
                              path: '/containerTrading'
                          })
                      }else{
                          location.reload();
                      }
                  }else if(this.currentModule === 'SpotSpace'){
                      if (split[1] !== '/spotSpace') {
                          this.$router.push({
                              path: '/spotSpace'
                          })
                      }else{
                          location.reload();
                      }
                  }else{
                      if (split[1] !== '/containerLeasing') {
                          this.$router.push({
                              path: '/containerLeasing'
                          })
                      }else{
                          location.reload();
                      }
                  }
              }else{
                  location.reload();
              }
          }
    },
    changeLangToUS(){
        this.$i18n.locale = 'en-US';
        localStorage.setItem('lang', this.$i18n.locale)
        this.updateMenu();
    },
    changeLangToCN(){
        this.$i18n.locale = 'zh-CN';
        localStorage.setItem('lang', this.$i18n.locale)
        this.updateMenu();
    },
    updateMenu(){
      this.tradingMenuList = [
        { name: this.$t('t.settings_companyInfo'), path: '/profileInfo',hovered: false,img: require('../../assets/company_setting_icon.png'),hoverImg: require('../../assets/company_suspension_setting_icon.png')},
        { name: this.$t('t.settings_password'), path: '/passManage',hovered: false,img: require('../../assets/password_setting_icon.png'),hoverImg: require('../../assets/password_suspension_setting_icon.png')},
        { name: this.$t('t.settings_order'), path: '/orderManage',hovered: false,img: require('../../assets/order_setting_icon.png'),hoverImg: require('../../assets/order_suspension_setting_icon.png') },
        { name: this.$t('t.settings_delegation'), path: '/containerDelegation',hovered: false,img: require('../../assets/delegation_setting_icon.png'),hoverImg: require('../../assets/delegation_suspension_setting_icon.png') },
        { name: this.$t('t.settings_inquiry'), path: '/containerInquiry',hovered: false,img: require('../../assets/inquiry_setting_icon.png'),hoverImg: require('../../assets/inquiry_suspension_setting_icon.png') },
        { name: this.$t('t.settings_factoryOrder'), path: '/factoryOrderManage',hovered: false,img: require('../../assets/factory_setting_icon.png'),hoverImg: require('../../assets/factory_suspension_setting_icon.png') },
        { name: this.$t('t.settings_depot'), path: '/depotInfo',hovered: false,img: require('../../assets/depot_setting_icon.png'),hoverImg: require('../../assets/depot_suspension_setting_icon.png') },
        { name: this.$t('t.settings_demand'), path: '/demandInfo',hovered: false,img: require('../../assets/demand_setting_icon.png'),hoverImg: require('../../assets/demand_suspension_setting_icon.png') }
      ];
      this.menuList = [
        { name: this.$t('t.settings_companyInfo'), path: '/profileInfo',hovered: false,img: require('../../assets/company_setting_icon.png'),hoverImg: require('../../assets/company_suspension_setting_icon.png')  },
        { name: this.$t('t.settings_password'), path: '/passManage' ,hovered: false,img: require('../../assets/password_setting_icon.png'),hoverImg: require('../../assets/password_suspension_setting_icon.png')},
        { name: this.$t('t.settings_order'), path: '/spotSpaceOrder',hovered: false,img: require('../../assets/order_setting_icon.png'),hoverImg: require('../../assets/order_suspension_setting_icon.png') },
        { name: this.$t('t.settings_inquiry'), path: '/spotSpaceInquiryOrder',hovered: false,img: require('../../assets/inquiry_setting_icon.png'),hoverImg: require('../../assets/inquiry_suspension_setting_icon.png') }
      ];
      this.leasingMenuList = [
        { name: this.$t('t.settings_companyInfo'), path: '/profileInfo',hovered: false,img: require('../../assets/company_setting_icon.png'),hoverImg: require('../../assets/company_suspension_setting_icon.png')},
        { name: this.$t('t.settings_password'), path: '/passManage',hovered: false,img: require('../../assets/password_setting_icon.png'),hoverImg: require('../../assets/password_suspension_setting_icon.png')},
        { name: this.$t('t.settings_inquiry'), path: '/containerLeasingInquiryOrder',hovered: false,img: require('../../assets/inquiry_setting_icon.png'),hoverImg: require('../../assets/inquiry_suspension_setting_icon.png') }
      ];
    },
    mousedown(event) {
        this.down = true;
        this.drag = false;
        this.hasChatOpened = true;
        this.visible = false;
        if(event.stopPropagation) event.stopPropagation();
        if(event.preventDefault) event.preventDefault();
        event.cancelBubble=true;
        event.returnValue=false;
        // 1,计算位置差
        // 因为clientX和offsetLeft的属性返回的位置不一样 要相减得到鼠标在拖动元素内实际点击的位置
        // 后面每一次拖动时都要减去这个差值 否则就会造成你拖动的位置一直都是元素的左上角 而不是你之前点击的位置
        this.disX = event.clientX - this.DragEl.offsetLeft;
        this.disY = event.clientY - this.DragEl.offsetTop;

        //2， 获取拖动元素的高度和容器的高度 为了下面进行限制元素拖动的范围
        let dragHeight = this.DragEl.offsetHeight;
        let dragWidth = this.DragEl.offsetWidth;

        // 添加鼠标移动事件
        document.onmousemove = (el) => {
            if(!this.down){
                return;
            }
            this.drag = true;
            // 3，获取鼠标移动位置
            let moveX = el.clientX - this.disX;
            let moveY = el.clientY - this.disY;
            // 4，限制拖动
            //控制范围：在元素 被拖拽的过程中 判断 元素的定位值 是否到达边界 如果到了 就不能在走了

            //4.1第一种 限制范围的判断
            // if(moveX <=0 || moveY <=0){ // 控制上边界和左边界
            //   return
            // }
            // if(moveX >= dragContainerWidth - dragWidth || moveY >= dragContainerHeight - dragHeight){
            //   return
            // }

            // 4.2 第二种限制方位的判断 建议使用第二种； 第一种靠边时经常会有边距，不太丝滑
            // 左边界
            if (moveX <= 210) {
                moveX = 210;
            }
            // 右边界 减去的值为div的宽度
            if (moveX >= this.totalWidth-50 ) {
                moveX = this.totalWidth-50  ;
            }
            // 上边界
            if (moveY <= 50) {
                moveY = 50;
            }
            // 上边界减去的值为div的高度
            if (moveY >= this.totalHeight-50) {
                moveY = this.totalHeight-50;
            }
            // 5, 开始移动
            this.DragEl.style.left = moveX + "px";
            this.DragEl.style.top = moveY + "px";
        };
       return false;
      },
    stopDrag() {
        document.onmousemove = null;
    },
    handleChildEvent(value) {
      this.currentServiceModule = value;
    },
    openChat () {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true
    },
    closeChat () {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false;
      if(!this.getLocalStoreItem('token')){
        return;
      }
      this.socket.onmessage = this.getMessage;
      this.getTotalUnreadCount();
    },
    handleScrollToTop () {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType () {
      console.log('Emit typing event')
    },
    editMessage(message){
      const m = this.tradingMessageList.find(m=>m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    },
    handleCloseWindow() {
      this.showChat = false;
      this.showDrag = true;
      if(!this.getLocalStoreItem('token')){
        return;
      }
      this.socket.onmessage = this.getMessage;
      this.getTotalUnreadCount();
    },
    init: function () {
        if(!this.getLocalStoreItem('token')){
            return;
        }
        if(typeof(WebSocket) === "undefined"){
            console.log("Browser is not supported!");
        }else{
            // 实例化socket
            console.log(this.path)
            this.socket = new WebSocket(this.path)
            // 监听socket连接
            this.socket.onopen = this.open
            // 监听socket错误信息
            this.socket.onerror = this.error
            // 监听socket消息
            this.socket.onmessage = this.getMessage

            this.socket.onclose = this.close
        }
    },
    open: function () {
        console.log("socket连接成功");
        this.heartbeat(this);
    },
    error: function () {
        clearInterval(this.heartbeat_timer)
        var self = this;
        setTimeout(() => {
            self.reconnect()
        },5000)
    },
    getMessage: function (msg) {
        try{
            let message = JSON.parse(msg.data);
            if(message.author !== 'me') {
                this.getTotalUnreadCount();
            }
        }catch(e){
        }
    },
    close: function () {
        console.log("socket已经关闭")
        clearInterval(this.heartbeat_timer)
        if (!this.active_close) {
            this.reconnect()
        }
    },
    heartbeat: (root) => {
        console.log('执行心跳')
        if (root.heartbeat_timer) {
            clearInterval(root.heartbeat_timer)
        }
        root.heartbeat_timer = setInterval(function (){
            root.socket.send('heartbeat')
        },60000)
    },
    reconnect: () => {
        console.log('执行重连')
        this.active_close = false
        if (this.socket) {
            this.socket.close()
        }
        this.init()
    },
    async getTotalUnreadCount(){
        if(!this.getLocalStoreItem('token')){
            return;
        }
        const param = {
            me: this.getLocalStoreItem('username')
        }
        this.loading = true;
        const res = await this.$axios.get('/bk/chat/getTotalUnreadCount', {
            params: param
        })
        this.loading = false;
        if(res || res >= 0){
            this.totalUnreadCount = res;
        }
      },
    showUmyNotice() {
      if (!this.hasChatOpened) {
        setTimeout(() => {
          this.visible = !this.visible;
          this.showUmyNotice();
        }, 10000);
      }
    },
    async getProfileInfo(){
      try{
        if(!this.getLocalStoreItem("username")){
          return
        }
        const param = {
          username: this.getLocalStoreItem("username")
        }
        const result = await this.$axios.get('/bk/userSetting/getUserInfo', {
          params: param
        })
        if(result === 401) {
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
            path:'/login'
          })
        }else if(result.username){
          this.item = result;

        }else{
          this.$message.error(this.$t('t.contact_IT'))
        }
      }catch (e) {

      }
    },
    toPremium(type){
      this.$router.push({
        path:`/register?type=${type}`
      })
    },
    startLeasing(){
      window.open('https://unit-match.force.com/marketplace/s/leasing')
    }
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    },
    showLoginIcon () {
        return !localStorage.getItem('token');
    }
  },
  watch: {
    currentLang: {
      handler (locale) {
        this.$nextTick(() => {
          if(this.$refs.entrustForm){
            this.$refs.entrustForm.fields.forEach(item => {
              if (item.validateState === 'error') {
                this.$refs.entrustForm.validateField(item.labelFor)
              }
            })
          }
        })
      },
      immediate: true
    }
  },
  mounted() {
      this.DragEl = document.getElementById("service");
      this.totalHeight = window.innerHeight;
      this.totalWidth = window.innerWidth;
      if(this.$refs.close){
        this.$refs.close.$off('close', this.handleCloseWindow);
      }
  },
  beforeDestroy() {
    if(this.$refs.close){
      this.$refs.close.$off('close', this.handleCloseWindow);
    }
  },
  destroyed () {
    // 销毁监听
    this.active_close = true;
    this.close();
  }
}
