//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "container_delegation",
  data(){
    const validateEntrustproductionYear = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_year')))
      } else {
        return callback()
      }
    }
    const validateColorSys = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('色系不能为空'))
      } else {
        return callback()
      }
    }
    const validateEntrustQty = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('库存不能为空'))
      } else {
        return callback()
      }
    }
    const validateEntrustPickupLoc = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_pickupLoc')))
      } else {
        return callback()
      }
    }
    const validateEntrustValidDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_validate')))
      } else {
        return callback()
      }
    }
    const validateEntrustPrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_price')))
      } else {
        return callback()
      }
    }
    const validateEntrustMinPrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_minPrice')))
      } else {
        return callback()
      }
    }
    return{
      delegationListInfo:[],
      isEmpty: true,
      editForm:{
        size: '',
        Type: '',
        containerStatus: '',
        productionYear: '',
        qty: '',
        colorSys: '',
        hasLockBox: false,
        hasForklift: false,
        pickupLoc: '',
        price: '',
        certContext: '',
        minPrice: '',
        validDate: '',
      },
      delegationRules: {
        qty: [{ required: true, validator: validateEntrustQty, trigger: 'blur' }],
        productionYear: [{ required: true, validator: validateEntrustproductionYear, trigger: 'blur' }],
        pickupLoc: [{ required: true, validator: validateEntrustPickupLoc, trigger: 'blur' }],
        price: [{ required: true, validator: validateEntrustPrice, trigger: 'blur' }],
        minPrice: [{ required: true, validator: validateEntrustMinPrice, trigger: 'blur' }],
        validDate: [{ required: true, validator: validateEntrustValidDate, trigger: 'blur' }],
        colorSys: [{ required: true, validator: validateColorSys, trigger: 'blur' }],
      },
      currentRecorId:'',
      fileList: [],
      dialogVisible: false,
      proofImage: '',
      dialogImageUrl: '',
      showDelegationDialog: false,
      deleteInfoDialog: false,
      portNameOptions: [],
      loading: false,
    }
  },
  methods:{

    async viewAllDelegation(){
      const params = {
        userId:this.getLocalStoreItem("userId"),
      }
      const result = await this.$axios.get('/bk/tradingEntrust/listEntrustSell', {
        params: params
      });

      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }

      this.delegationListInfo = result;
      this.isEmpty = this.delegationListInfo.length === 0;
    },
    continueBrowsing(){
      this.$router.push({
        path:'/containerTrading'
      })
    },
    openEditDialog(item){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      this.editForm = {
        ...item,
        size: item.sizeType.substring(0,2),
        type: item.sizeType.substring(2,4)
      }
      this.editForm.hasLockBox = this.editForm.hasLockBox === 'Yes' ?  true : false;
      this.editForm.hasForklift = this.editForm.hasForklift === 'Yes' ?  true : false;
      this.showDelegationDialog = true;
    },
    async updateDelegationRecord(){

      await this.$refs.editForm.validate()

      this.editForm.sizeType = this.editForm.size + this.editForm.type;
      this.editForm.lastPickupDate = this.dateFormat(this.editForm.lastPickupDate);
      this.editForm.hasLockBox = this.editForm.hasLockBox ? 'Yes': 'No';
      this.editForm.hasForklift = this.editForm.hasForklift ? 'Yes': 'No';
      const params = {
        ...this.editForm,
        username: this.getLocalStoreItem('username')
      }
      const result = await this.$axios.post('/bk/tradingEntrust/updateEntrust', params);
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }else if(result === 'success'){
        this.item = this.editForm;
        this.showDelegationDialog = false;
        this.$message({
          message: 'Update Success',
          type: 'success'
        });
        location.reload();
      }else{
        this.$message.error(this.$t('t.contact_IT'))
      }
    },
    openDeleteDialog(item){
      this.currentRecorId = item.id;
      this.deleteInfoDialog = true;
    },
    async deleteDelegationRecord(){
      const params = {
        id: this.currentRecorId,
        username: this.getLocalStoreItem('username')
      }
      console.log(params);
      const result = await this.$axios.post('/bk/tradingEntrust/deleteEntrust', params);
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }else if(result === 'success'){
        this.$message({
          message: 'Delete Success',
          type: 'success'
        });
        location.reload();
      }else{
        this.$message.error(this.$t('t.contact_IT'))
      }
      this.currentRecorId = '';
      this.deleteInfoDialog = false;
    },
    viewDelegationDetail(item){
      console.log(item)
      try {
          let url = this.baseURL + '/#/delegationDetail/' + item.entrustNo;
          window.open(url);
      } catch (e) {
        console.log(e, 'e')
      }
    },
    getFile (file, fileList, num) {
      this.getBase64(file.raw).then(res => {
        const params = res
        console.log(params, 'params')
        this.proofImage = params
        if (num === 1) {
          this.editForm.certContext = this.proofImage
        }

      })
    },
    getBase64 (file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },
    handleUploadRemove (file, fileList, num) {
      this.proofImage = ''
      if (num === 1) {
        this.editForm.certContext = this.proofImage
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    dateFormat(dateStr){
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;

      const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
      return formattedDate;
    },
    async searchPort(query){
      try {
        const param = {
          portName: query
        }
        this.loading = true;
        const res = await this.$axios.get('/bk/port/searchPort', {
          params: param
        })
        this.loading = false;
        this.portNameOptions = res;
      } catch (e) {
        console.log(e, 'e')
      }
    },
  },
  created() {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
    this.viewAllDelegation();
    this.searchPort('SHA');
  },
  computed: {
      currentLang () {
          return this.$i18n.locale
      }
  },
  watch: {
    }
}
