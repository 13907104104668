//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Header from './Header.vue'
import MessageList from './MessageList.vue'
import UserInput from './UserInput.vue'
import UserList from './UserList.vue'
import ServiceType from "./ServiceType.vue";

export default {
  components: {
    Header,
    MessageList,
    UserInput,
    UserList,
    ServiceType
  },
  props: {
    showEmoji: {
      type: Boolean,
      default: false
    },
    showUmy: {
      type: Boolean,
      default: false
    },
    showFile: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    participants: {
      type: Array,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: () => false
    },
    placeholder: {
      type: String,
      required: true
    },
    showTypingIndicator: {
      type: String,
      required: true
    },
    alwaysScrollToBottom: {
      type: Boolean,
      required: true
    },
    messageStyling: {
      type: Boolean,
      required: true
    },
    socket: {
        type: WebSocket,
        required: true
    },
  },
  data() {
    return {
      showUserList: false,
      currentModule: 'Trading',
      messageList: [],
      updateMessageList: [],
      unreadCount: 0,
      tradingMessageList: [],
      tradingUnreadMessageList: [],
      spotMessageList: [],
      spotUnreadMessageList: [],
      leasingMessageList: [],
      leasingUnreadMessageList: [],
      path: this.baseURL.replace('https', 'ws') + "/WebSocket/" + this.getLocalStoreItem("username"),
      heartbeat_timer: null, // 用于心跳检测
      active_close: false, // 是否主动关闭
      chatEl: null, //拖动窗口
      // 位置差
      disX: 0,
      disY: 0,
      drag: false,
      down: false,
      showChat: false,
      showDrag: true,
      colors: {
        header: {
          bg: '#FFFFFF',
          text: '#333333'
        },
        launcher: {
          bg: '#45BFDF'
        },
        messageList: { //整个聊天背景颜色
          bg: '#ffffff'
        },
        sentMessage: { //己方发送消息颜色
          bg: '#45BFDF',
          text: '#ffffff'
        },
        receivedMessage: { //对方消息颜色
          bg: '#F0FAFC',
          text: '#222222'
        },
        userInput: { // 输入框颜色
          bg: '#ffffff',
          text: '#565867'
        }
      }, // specifies the color scheme for the component
    }
  },
  computed: {
    messages() {
      return this.messageList;
    },
    unreadCountList(){
      return [this.tradingUnreadMessageList.length, this.spotUnreadMessageList.length, this.leasingUnreadMessageList.length];
    },
    serviceTitle(){
      let moduleText;
      if(this.currentModule === 'Trading'){
        moduleText = this.$t('t.service_chat_item_trading');
      } else if(this.currentModule === 'SpotSpace'){
        moduleText = this.$t('t.service_chat_item_spot')
      } else if(this.currentModule === 'Leasing'){
        moduleText = this.$t('t.service_chat_item_leasing')
      }
      return this.$t('t.service_chat_title') + moduleText
    }
  },
  methods: {
    handleUserListToggle(showUserList) {
      this.showUserList = showUserList
    },
    getSuggestions() {
      return this.messages.length > 0 ? this.messages[this.messages.length - 1].suggestions : []
    },
    changeTab(module){
      console.log(module);
      this.currentModule = module;
      if(module === 'SpotSpace'){
        this.colors.sentMessage.bg = '#346DB2'
      }else {
        this.colors.sentMessage.bg = '#45BFDF'
      }
      if(!this.getLocalStoreItem('token')){
        return ;
      }
      this.displayChatInfo();
      this.$emit('child-event', this.currentModule);
    },
    displayTrading(){
      if(this.tradingUnreadMessageList.length === 0){
        this.messageList = this.tradingMessageList;
        return;
      }
      if(this.tradingMessageList.length <= 6){
        let maxShowCount = 6 - this.tradingMessageList.length;
        if(this.tradingUnreadMessageList.length  <= maxShowCount){
          this.tradingMessageList.push(...this.tradingUnreadMessageList);
            this.updateMessageList = this.tradingUnreadMessageList.map(item => (item.id));
            this.tradingUnreadMessageList = [];
        }else{
          let showList = this.tradingUnreadMessageList.slice(0,maxShowCount);
          this.tradingMessageList.push(...showList);
          this.updateMessageList = showList.map(item => (item.id));
          this.tradingUnreadMessageList = this.tradingUnreadMessageList.splice(maxShowCount, this.tradingUnreadMessageList.length  - maxShowCount);
        }
      }
      this.messageList = this.tradingMessageList;
      this.unreadCount = this.tradingUnreadMessageList.length;
    },
    displaySpot(){
      if(this.spotUnreadMessageList.length === 0){
        this.messageList = this.spotMessageList;
        return;
      }
      if(this.spotMessageList.length <= 6){
        let maxShowCount = 6 - this.spotMessageList.length;
        if(this.spotUnreadMessageList.length  <= maxShowCount){
          this.spotMessageList.push(...this.spotUnreadMessageList);
          this.updateMessageList = this.spotUnreadMessageList.map(item => (item.id));
          this.spotUnreadMessageList = [];
        }else{
          let showList = this.spotUnreadMessageList.slice(0,maxShowCount);
          this.spotMessageList.push(...showList);
          this.updateMessageList = showList.map(item => (item.id));
          this.spotUnreadMessageList = this.spotUnreadMessageList.splice(maxShowCount, this.spotUnreadMessageList.length  - maxShowCount);
        }
      }
      this.messageList = this.spotMessageList;
      this.unreadCount = this.spotUnreadMessageList.length;
    },
    displayLeasing(){
      if(this.leasingUnreadMessageList.length === 0){
        this.messageList = this.leasingMessageList;
        return;
      }
      if(this.leasingMessageList.length <= 6){
        let maxShowCount = 6 - this.leasingMessageList.length;
        if(this.leasingUnreadMessageList.length  <= maxShowCount){
          this.leasingMessageList.push(...this.leasingUnreadMessageList);
          this.updateMessageList = this.leasingUnreadMessageList.map(item => (item.id));
          this.leasingUnreadMessageList = [];
        }else{
          let showList = this.leasingUnreadMessageList.slice(0,maxShowCount);
          this.leasingMessageList.push(...showList);
          this.updateMessageList = this.showList.map(item => (item.id));
          this.leasingUnreadMessageList = this.leasingUnreadMessageList.splice(maxShowCount, this.leasingUnreadMessageList.length  - maxShowCount);
        }
      }
      this.messageList = this.tradingMessageList;
      this.unreadCount = this.leasingUnreadMessageList.length;
    },
    displayChatInfo(){
      if(!this.getLocalStoreItem('token')){
        return ;
      }
      if(this.currentModule === 'Trading'){
        this.displayTrading();
      }else if( this.currentModule === 'SpotSpace' ){
        this.displaySpot();
      }else if( this.currentModule === 'Leasing' ){
        this.displayLeasing();
      }
      //调用已读接口
      this.readMessage();
    },
    handleRead(){
      let messages = [];
      let unreadMessages = [];
      if(this.currentModule === 'Trading'){
        messages = this.tradingMessageList;
        unreadMessages = this.tradingUnreadMessageList;
        this.tradingUnreadMessageList = [];
      }else if( this.currentModule === 'SpotSpace' ){
        messages = this.spotMessageList;
        unreadMessages = this.spotUnreadMessageList;
        this.spotUnreadMessageList = [];
      }else if( this.currentModule === 'Leasing' ){
        messages = this.leasingMessageList;
        unreadMessages = this.leasingUnreadMessageList;
        this.leasingUnreadMessageList = [];
      }
      // unreadMessages = unreadMessages.map(item => ({ ...item, isRead: true }))
      messages.push(...unreadMessages);
      this.updateMessageList = unreadMessages.map(item => (item.id));
      this.messageList = messages;
      this.unreadCount = 0;

      //调用已读接口
        this.readMessage();
    },
    async readMessage(){
        const params = this.updateMessageList;
        const result = await this.$axios.post('/bk/chat/changeToRead', params);
        if(result === 'success'){
            this.updateMessageList = [];
        }
    },
    async onMessageWasSent (message)  {
        console.log('send');
        if(!this.getLocalStoreItem('token')){
            localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
            this.$router.push({
                path:'/login'
            })
            return ;
        }
      // called when the user sends a message
      if(message.type === 'file' || message.type ==='img'){
        const params = {
          senderGroup: this.getLocalStoreItem('username'),
          fileContent: message.fileContent,
          fileName: message.fileName
        }
        const results = await this.$axios.post('/bk/saasFile/uploadFile', params);
        console.log(results)
        if(results){
          message.fileUrl = results;
          message.fileContent  = null;
        }else {
          return;
        }
      }

      let param = {
          ...message,
          senderName: this.getLocalStoreItem('username'),
          senderGroup: this.getLocalStoreItem('username'),
          senderId: this.getLocalStoreItem('userId'),
          recieverGroup: this.currentModule
      }
      const result = await this.$axios.post('/bk/chat/saveMsg', param);
      if(result.id){
        this.send(JSON.stringify(result));
        if(message){
            this.handleRead();
        }
        if(this.currentModule === 'Trading'){
            this.tradingMessageList.push(result);
        }else if(this.currentModule === 'SpotSpace'){
            this.spotMessageList.push(result);
        }else if(this.currentModule === 'Leasing'){
            this.leasingMessageList.push(result);
        }
      }
    },
    getCurrentDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1; // 月份从0开始，所以需要加1
      const date = now.getDate();
      const hours = now.getHours();
      const minutes = String(now.getMinutes()).padStart(2, '0'); // 使用padStart方法添加前导零
      const formattedDateTime = `${year}-${month}-${date} ${hours}:${minutes}`;
      return formattedDateTime;
    },
    getMessage: function (msg) {
        try{
            let message = JSON.parse(msg.data);
            if(message.msgType === 'read'){
                if(message.username === 'Trading'){
                    for(let i =0; i < message.ids.length; i++){
                        const index = this.tradingMessageList.findIndex(item => item.id == message.ids[i]);
                        this.tradingMessageList[index].read = true;
                    }

                }else if(message.username === 'SpotSpace'){
                    for(let i =0; i < message.ids.length; i++) {
                        const index = this.spotMessageList.findIndex(item => item.id == message.ids[i]);
                        this.spotMessageList[index].read = true;
                    }
                }else if(message.username === 'Leasing'){
                    for(let i =0; i < message.ids.length; i++) {
                        const index = this.leasingMessageList.findIndex(item => item.id == message.ids[i]);
                        this.leasingMessageList[index].read = true;
                    }
                }
            }else if(message.msgType === 'self-read'){
                if(message.username === 'Trading'){
                    this.tradingMessageList.push(...this.tradingUnreadMessageList);
                    this.tradingUnreadMessageList = [];
                }else if(message.username === 'SpotSpace'){
                    this.spotMessageList.push(...this.spotUnreadMessageList);
                    this.spotUnreadMessageList = [];
                }else if(message.username === 'Leasing'){
                    this.leasingMessageList.push(...this.leasingUnreadMessageList);
                    this.leasingUnreadMessageList = [];
                }
            }else if(!message.msgType){
                if(message.author === 'me'){
                    this.handleRead();
                    if(message.recieverGroup === 'Trading'){
                        this.tradingMessageList.push(message);
                    }else if(message.recieverGroup === 'SpotSpace'){
                        this.spotMessageList.push(message);
                    }else if(message.recieverGroup === 'Leasing'){
                        this.leasingMessageList.push(message);
                    }
                }else{
                    if(message.senderGroup === 'Trading'){
                        this.tradingUnreadMessageList.push(message);
                    }else if(message.senderGroup === 'SpotSpace'){
                        this.spotUnreadMessageList.push(message);
                    }else if(message.senderGroup === 'Leasing'){
                        this.leasingUnreadMessageList.push(message);
                    }
                }
            }
            this.displayChatInfo();
        }catch(e){
        }
    },
    // 发送消息给被连接的服务端
    send: function (params) {
        this.socket.send(params)
    },
    async getHistoryChat(){
        if(!this.getLocalStoreItem('token')){
          return ;
        }
        const params = {
            username: this.getLocalStoreItem("username")
        }
        const res = await this.$axios.get('/bk/chat/getUserChatHistory', {
            params: params
        })
        if(res){
            this.tradingUnreadMessageList = res.tradingUnreadMessageList;
            this.tradingMessageList = res.tradingMessageList;
            this.spotUnreadMessageList = res.spotUnreadMessageList;
            this.spotMessageList = res.spotMessageList;
            this.leasingUnreadMessageList = res.leasingUnreadMessageList;
            this.leasingMessageList = res.leasingMessageList;
            this.displayChatInfo();
        }
    },
    handleClose(){
        this.$emit('close');
    },
    mousedown(event) {
      this.down = true;
      if(event.stopPropagation) event.stopPropagation();
      if(event.preventDefault) event.preventDefault();
      event.cancelBubble=true;
      event.returnValue=false;
      // 1,计算位置差
      // 因为clientX和offsetLeft的属性返回的位置不一样 要相减得到鼠标在拖动元素内实际点击的位置
      // 后面每一次拖动时都要减去这个差值 否则就会造成你拖动的位置一直都是元素的左上角 而不是你之前点击的位置
      this.disX = event.clientX - this.chatEl.offsetLeft;
      this.disY = event.clientY - this.chatEl.offsetTop;

      //2， 获取拖动元素的高度和容器的高度 为了下面进行限制元素拖动的范围
      let dragHeight = this.chatEl.offsetHeight;
      let dragWidth = this.chatEl.offsetWidth;

      // 添加鼠标移动事件
      document.onmousemove = (el) => {
        if(!this.down){
          return;
        }
        this.drag = true;
        // 3，获取鼠标移动位置
        let moveX = el.clientX - this.disX;
        let moveY = el.clientY - this.disY;
        // 4，限制拖动
        //控制范围：在元素 被拖拽的过程中 判断 元素的定位值 是否到达边界 如果到了 就不能在走了

        //4.1第一种 限制范围的判断
        // if(moveX <=0 || moveY <=0){ // 控制上边界和左边界
        //   return
        // }
        // if(moveX >= dragContainerWidth - dragWidth || moveY >= dragContainerHeight - dragHeight){
        //   return
        // }

        // 4.2 第二种限制方位的判断 建议使用第二种； 第一种靠边时经常会有边距，不太丝滑
        // 左边界
        if (moveX <= this.totalWidth-50) {
          moveX = this.totalWidth-50;
        }
        // 右边界 减去的值为div的宽度
        if (moveX >= this.totalWidth-50 ) {
          moveX = this.totalWidth-50;
        }
        // 上边界
        if (moveY <= 50) {
          moveY = 50;
        }
        // 上边界减去的值为div的高度
        if (moveY >= this.totalHeight-50) {
          moveY = this.totalHeight-50;
        }
        // 5, 开始移动
        this.chatEl.style.left = moveX + "px";
        this.chatEl.style.top = moveY + "px";
      };
      event.stopPropagation();
      return false;
    },
    stopDrag() {
        document.onmousemove = null;
    }
  },
  mounted(){
    this.chatEl = document.getElementById("chat");
    this.$refs.tab.$on('tabchange',this.changeTab)
    this.$refs.messagetab.$on('read-message',this.handleRead)
    this.$refs.close.$on('close-chat',this.handleClose)
  },
  created() {
    if(!this.getLocalStoreItem('token')){
      return ;
    }
    //将接受消息处理方法切换成当前组件的 getMessage 方法
    this.socket.onmessage = this.getMessage
    this.getHistoryChat();
    this.displayChatInfo();
  },
}
