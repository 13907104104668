import { render, staticRenderFns } from "./demand_info.vue?vue&type=template&id=e4221bba&scoped=true&"
import script from "./demand_info.vue?vue&type=script&lang=js&"
export * from "./demand_info.vue?vue&type=script&lang=js&"
import style0 from "./demand_info.vue?vue&type=style&index=0&id=e4221bba&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4221bba",
  null
  
)

export default component.exports