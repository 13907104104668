//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CloseIcon from '../../../assets/close-icon.png'
import OpenIcon from '../../../assets/logo-no-bg.svg'
import FileIcon from '../../../assets/file.svg'
import CloseIconSvg from '../../../assets/close.svg'
import ChatWindow from "../chat/ChatWindow.vue";

import companyIcon from "../../../assets/company_info_icon.png"
import orderIcon from "../../../assets/order_info_icon.png"
import passwordIcon from "../../../assets/password_info_icon.png"
import memberIcon from "../../../assets/member_info_icon.png"
import manufacturerIcon from "../../../assets/manufacturer_info_icon.png"
import inquiryIcon from "../../../assets/inquiry_info_icon.png"
import entrustIcon from "../../../assets/entrust_info_icon.png"
import depotIcon from "../../../assets/depot_info_icon.png"
import demandIcon from "../../../assets/demand_info_icon.png"
export default {
  name: "mb_one_stop_trading",
  components:{
    ChatWindow
  },
  created(){
      this.currentModule = (location.hash.indexOf('/spotSpace') != -1) ? 'SpotSpace' : (location.hash.indexOf('/containerLeasing') != -1)? 'Leasing' : 'Trading';
      window.addEventListener('hashchange', this.hashChange)
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
      this.currentVip = this.getLocalStoreItem('um-vip') === '1';
      this.tradingMenuList = [
          { name: this.$t('t.mobile_settings_companyInfo'), path: '/profileInfo', img: companyIcon },
          { name: this.$t('t.mobile_settings_password'), path: '/passManage', img: passwordIcon },
          { name: this.$t('t.mobile_settings_order'), path: '/orderManage', img: orderIcon },
          { name: this.$t('t.mobile_settings_delegation'), path: '/containerDelegation', img: entrustIcon },
          { name: this.$t('t.mobile_settings_inquiry'), path: '/containerInquiry' , img: inquiryIcon},
          { name: this.$t('t.mobile_settings_factoryOrder'), path: '/factoryOrderManage', img: manufacturerIcon },
          { name: this.$t('t.mobile_settings_depot'), path: '/depotInfo',img: depotIcon},
          { name: this.$t('t.mobile_settings_demand'), path: '/demandInfo',img: demandIcon},
          { name: this.$t('t.mobile_settings_member'), path: '/memberInfo', img: memberIcon },
      ];
      this.spotMenuList =  [
          { name: this.$t('t.mobile_settings_companyInfo'), path: '/profileInfo', img: companyIcon },
          { name: this.$t('t.mobile_settings_password'), path: '/passManage', img: passwordIcon },
          { name: this.$t('t.mobile_settings_order'), path: '/spotSpaceOrder', img: orderIcon },
          { name: this.$t('t.mobile_settings_inquiry'), path: '/spotSpaceInquiryOrder' , img: inquiryIcon },
          { name: this.$t('t.mobile_settings_member'), path: '/memberInfo', img: memberIcon },
      ];
      this.leasingMenuList = [
          { name: this.$t('t.mobile_settings_companyInfo'), path: '/profileInfo', img: companyIcon  },
          { name: this.$t('t.mobile_settings_password'), path: '/passManage' , img: passwordIcon },
          { name: this.$t('t.mobile_settings_inquiry'), path: '/containerLeasingInquiryOrder', img: inquiryIcon },
          { name: this.$t('t.mobile_settings_member'), path: '/memberInfo', img: memberIcon },
      ];
      // if(this.currentVip){
      //   this.tradingMenuList.push({ name: this.$t('t.mobile_settings_member'), path: '/memberInfo', img: memberIcon })
      //   this.spotMenuList.push({ name: this.$t('t.mobile_settings_member'), path: '/memberInfo', img: memberIcon })
      //   this.leasingMenuList.push({ name: this.$t('t.mobile_settings_member'), path: '/memberInfo', img: memberIcon })
      // }
      this.init();
      this.getTotalUnreadCount();
      this.showUmyNotice();
  },
  data(){
    return{
      dialogFormVisible: false,
      dialogVisible: false,
      dialogImageUrl: '',
      fileList: [],
      showSetting: false,
      tradingMenuList: [],
      spotMenuList: [],
      leasingMenuList: [],
      timeOutEvent: 0,
      longClick: 0,
      // 手指原始位置
      oldMousePos: {},
      // 元素原始位置
      oldNodePos: {},
      btnType: 'right',
      currentModule: 'Trading',
      showLogoutTip: false,
      showChat: false,
      icons:{
        open:{
          img: OpenIcon,
          name: 'default',
        },
        close:{
          img: CloseIcon,
          name: 'default',
        },
        file:{
          img: FileIcon,
          name: 'default',
        },
        closeSvg:{
          img: CloseIconSvg,
          name: 'default',
        },
      },
      participants: [
        {
          id: 'user1',
          name: 'Matteo',
          imageUrl: 'https://avatars3.githubusercontent.com/u/1915989?s=230&v=4'
        },
        {
          id: 'user2',
          name: 'Support',
          imageUrl: 'https://avatars3.githubusercontent.com/u/37018832?s=200&v=4'
        }
      ], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
      titleImageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
      isChatOpen: true, // to determine whether the chat window should be open or closed
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      alwaysScrollToBottom: true, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true,
      showCloseButton: {
        type: Boolean,
        default: true
      },
      currentServiceModule: 'Trading',
      showDrag: true,
      socket: {},
      path: this.baseURL.replace('https', 'ws') + "/WebSocket/" + this.getLocalStoreItem("username"),
      heartbeat_timer: null, // 用于心跳检测
      active_close: false, // 是否主动关闭
      totalUnreadCount: 0,
      visible: true,
      hasChatOpened: false,
      currentVip:  false,
    }
  },
  methods: {
    toAbout(){
      this.$router.push({
        path: '/about'
      })
    },
    hashChange(){
        this.currentModule = (location.hash.indexOf('/spotSpace') != -1) ? 'SpotSpace' : (location.hash.indexOf('/containerLeasing') != -1)? 'Leasing' : 'Trading';
    },
    closeSetting() {
      console.log('closeSetting')
      setTimeout(() => {
        this.showSetting = false;
      }, 300)
    },
    navigatorPage(path) {
      if (path === 'en-US' || path === 'zh-CN') {
        this.$i18n.locale = path;
        this.showSetting = false;
        localStorage.setItem('lang', this.$i18n.locale);
        this.updateMenu();
        return;
      }
      let splits = window.location.href.split('#');

      if (path && splits.length > 1 && splits[1] !== path) {
        if(path === '/memberInfo' && !this.currentVip){
          this.$router.push({
            path:`/register?type=upgrade`
          })
          return;
        }
        this.showSetting = false
        this.$router.push(path)
      }
    },
    clickSetting() {
      this.showSetting = !this.showSetting;
    },
    getFile(file, fileList, num) {
      this.getBase64(file.raw).then(res => {
        const params = res
        console.log(params, 'params')
        this.proofImage = params
        if (num === 1) {
          this.infoForm.certContext = this.proofImage
        }

      })
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },
    handleUploadRemove(file, fileList, num) {
      this.proofImage = ''
      if (num === 1) {
        this.infoForm.certContext = this.proofImage
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    clickReturn() {
      this.$router.back();
    },
    toTrading() {
      this.currentModule = 'Trading';
      this.$router.push({
        path: '/containerTrading'
      })
    },
    toSpot() {
      this.currentModule = 'SpotSpace';
      this.$router.push({
        path: '/spotSpace'
      })
    },
    toLeasing() {
        this.currentModule = 'Leasing'
        this.$router.push({
            path:'/containerLeasing'
        })
    },
    toLogout(){
        this.showLogoutTip = true;
    },
    async logout() {
      const addResult = await this.$axios.post('/bk/auth/signout');
      if (addResult.message === 'You\'ve been signed out!') {
        this.showLogoutTip = false;
        localStorage.removeItem('token');
        localStorage.removeItem("username");
        localStorage.removeItem("userId");
        localStorage.removeItem("um-vip");
        localStorage.removeItem("depotVip");
        localStorage.removeItem("role");
        localStorage.removeItem("lastRefreshTime");
        let split = location.href.split('#');
        if (split.length > 1) {
          if(this.currentModule === 'Trading'){
              if (split[1] !== '/containerTrading') {
                  this.$router.push({
                      path: '/containerTrading'
                  })
              }else{
                  location.reload();
              }
          }else if(this.currentModule === 'SpotSpace'){
              if (split[1] !== '/spotSpace') {
                  this.$router.push({
                      path: '/spotSpace'
                  })
              }else{
                  location.reload();
              }
          }else{
              if (split[1] !== '/containerLeasing') {
                  this.$router.push({
                      path: '/containerLeasing'
                  })
              }else{
                  location.reload();
              }
          }
        }else{
            location.reload();
        }
      }
    },
    toLogin() {
      localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
      this.$router.push({
        path: '/login'
      })
    },
    updateMenu() {
      this.tradingMenuList = [
        { name: this.$t('t.mobile_settings_companyInfo'), path: '/profileInfo', img: companyIcon },
        { name: this.$t('t.mobile_settings_password'), path: '/passManage', img: passwordIcon },
        { name: this.$t('t.mobile_settings_order'), path: '/orderManage', img: orderIcon },
        { name: this.$t('t.mobile_settings_delegation'), path: '/containerDelegation', img: entrustIcon },
        { name: this.$t('t.mobile_settings_inquiry'), path: '/containerInquiry' , img: inquiryIcon},
        { name: this.$t('t.mobile_settings_factoryOrder'), path: '/factoryOrderManage', img: manufacturerIcon },
        { name: this.$t('t.mobile_settings_depot'), path: '/depotInfo',img: depotIcon},
        { name: this.$t('t.mobile_settings_demand'), path: '/demandInfo',img: demandIcon},
        { name: this.$t('t.mobile_settings_member'), path: '/memberInfo', img: memberIcon },
      ];
      this.spotMenuList = [
        { name: this.$t('t.mobile_settings_companyInfo'), path: '/profileInfo', img: companyIcon },
        { name: this.$t('t.mobile_settings_password'), path: '/passManage', img: passwordIcon },
        { name: this.$t('t.mobile_settings_order'), path: '/spotSpaceOrder', img: orderIcon },
        { name: this.$t('t.mobile_settings_inquiry'), path: '/spotSpaceInquiryOrder' , img: inquiryIcon },
        { name: this.$t('t.mobile_settings_member'), path: '/memberInfo', img: memberIcon },
      ];
      this.leasingMenuList = [
        { name: this.$t('t.mobile_settings_companyInfo'), path: '/profileInfo', img: companyIcon  },
        { name: this.$t('t.mobile_settings_password'), path: '/passManage' , img: passwordIcon },
        { name: this.$t('t.mobile_settings_inquiry'), path: '/containerLeasingInquiryOrder', img: inquiryIcon },
        { name: this.$t('t.mobile_settings_member'), path: '/memberInfo', img: memberIcon },
      ];
      // if(this.currentVip){
      //   this.tradingMenuList.push({ name: this.$t('t.mobile_settings_member'), path: '/memberInfo', img: memberIcon })
      //   this.spotMenuList.push({ name: this.$t('t.mobile_settings_member'), path: '/memberInfo', img: memberIcon })
      //   this.leasingMenuList.push({ name: this.$t('t.mobile_settings_member'), path: '/memberInfo', img: memberIcon })
      // }
    },
    changeLangToUS() {
      this.$i18n.locale = 'en-US';
      localStorage.setItem('lang', this.$i18n.locale)
      this.updateMenu();
    },
    changeLangToCN() {
      this.$i18n.locale = 'zh-CN';
      localStorage.setItem('lang', this.$i18n.locale)
      this.updateMenu();
    },
    showServiceContract() {
      this.showDrag = false;
      this.showChat = true;
    },
    openChat () {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true
    },
    touchstart(ev) {
        this.visible = false;
        this.hasChatOpened = true;
        // 定时器控制长按时间，超过100毫秒开始进行拖拽
        this.timeOutEvent = setTimeout(() => {
            this.longClick = 1;
        }, 100);
        const selectDom = ev.currentTarget;
        const { pageX, pageY } = ev.touches[0]; // 手指位置
        const { offsetLeft, offsetTop } = selectDom; // 元素位置
        // 手指原始位置
        this.oldMousePos = {
            x: pageX,
            y: pageY
        };
        // 元素原始位置
        this.oldNodePos = {
            x: offsetLeft,
            y: offsetTop
        };
        selectDom.style.left = `${offsetLeft}px`;
        selectDom.style.top = `${offsetTop}px`;
    },
    touchMove(ev) {
        // 未达到100毫秒就移动则不触发长按，清空定时器
        clearTimeout(this.timeOutEvent);
        if (this.longClick === 1) {
            const selectDom = ev.currentTarget;
            // x轴偏移量
            const lefts = this.oldMousePos.x - this.oldNodePos.x;
            // y轴偏移量
            const tops = this.oldMousePos.y - this.oldNodePos.y;
            const { pageX, pageY } = ev.touches[0]; // 手指位置
            selectDom.style.left = `${pageX - lefts}px`;
            selectDom.style.top = `${pageY - tops}px`;
        }
    },
    touchEnd(ev) {
        // 清空定时器
        clearTimeout(this.timeOutEvent);
        if (this.longClick === 1) {
            this.longClick = 0;
            const selectDom = ev.currentTarget;
            const {clientWidth, clientHeight} = document.body;
            const {offsetLeft, offsetTop} = selectDom;
            selectDom.style.left =
                (offsetLeft + 30) > (clientWidth / 2) ?
                    'calc(100% - 100px)' : 0;
            if (offsetTop < 30) {
                selectDom.style.top = '30px';
            } else if (offsetTop + 36 > clientHeight) {
                selectDom.style.top = `${clientHeight - 30}px`;
            }
            this.btnType =
                (offsetLeft + 50) > (clientWidth / 2) ?
                    'right' : 'left';
        }
    },
    handleChildEvent(value) {
      this.currentServiceModule = value;
    },
    closeChat () {
      if(!this.getLocalStoreItem('token')){
        return;
      }
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false;
      this.socket.onmessage = this.getMessage;
      this.getTotalUnreadCount();
    },
    handleScrollToTop () {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType () {
      console.log('Emit typing event')
    },
    editMessage(message){
      const m = this.tradingMessageList.find(m=>m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    },
    handleCloseWindow() {
      this.showChat = false;
      this.showDrag = true;
      if(!this.getLocalStoreItem('token')){
        return;
      }
      this.socket.onmessage = this.getMessage;
      this.getTotalUnreadCount();
    },
    init: function () {
      if(!this.getLocalStoreItem('token')){
        return;
      }
      if(typeof(WebSocket) === "undefined"){
        console.log("Browser is not supported!");
      }else{
        // 实例化socket
        console.log(this.path)
        this.socket = new WebSocket(this.path)
        // 监听socket连接
        this.socket.onopen = this.open
        // 监听socket错误信息
        this.socket.onerror = this.error
        // 监听socket消息
        this.socket.onmessage = this.getMessage

        this.socket.onclose = this.close
      }
    },
    open: function () {
      console.log("socket连接成功");
      this.heartbeat(this);
    },
    error: function () {
      clearInterval(this.heartbeat_timer)
      var self = this;
      setTimeout(() => {
        self.reconnect()
      },5000)
    },
    getMessage: function (msg) {
      try{
        let message = JSON.parse(msg.data);
        if(message.author !== 'me') {
          this.getTotalUnreadCount();
        }
      }catch(e){
      }
    },
    close: function () {
      console.log("socket已经关闭")
      clearInterval(this.heartbeat_timer)
      if (!this.active_close) {
        this.reconnect()
      }
    },
    heartbeat: (root) => {
      console.log('执行心跳')
      if (root.heartbeat_timer) {
        clearInterval(root.heartbeat_timer)
      }
      root.heartbeat_timer = setInterval(function (){
        root.socket.send('heartbeat')
      },60000)
    },
    reconnect: () => {
      console.log('执行重连')
      this.active_close = false
      if (this.socket) {
        this.socket.close()
      }
      this.init()
    },
    async getTotalUnreadCount(){
      if(!this.getLocalStoreItem('token')){
        return;
      }
      const param = {
        me: this.getLocalStoreItem('username')
      }
      this.loading = true;
      const res = await this.$axios.get('/bk/chat/getTotalUnreadCount', {
        params: param
      })
      this.loading = false;
      if(res || res >= 0){
        this.totalUnreadCount = res;
      }
    },
    showUmyNotice() {
      if (!this.hasChatOpened) {
        setTimeout(() => {
          this.visible = !this.visible;
          this.showUmyNotice();
        }, 10000);
      }
    },
    handleClose(){

    }
  },
  computed: {
      currentLang () {
          return this.$i18n.locale
      },
      showLoginIcon () {
          return !localStorage.getItem('token');
      }
  },
  mounted() {
    if(this.$refs.close){
      this.$refs.close.$on('close', this.handleCloseWindow);
    }
  },
  beforeDestroy() {
    if(this.$refs.close){
      this.$refs.close.$off('close', this.handleCloseWindow);
    }
  },
  destroyed () {
    // 销毁监听
    this.active_close = true;
    this.close();
  }
}
