//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "container_factory_purchase",
  data(){
    const validateSize = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_size')))
      } else {
        return callback()
      }
    }
    const validateType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_type')))
      } else {
        return callback()
      }
    }
    const validateQty = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_qty')))
      } else {
        return callback()
      }
    }
    const validateColorSys = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_colorNumber')))
      } else {
        return callback()
      }
    }
    const validatePickupLoc = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_pickupLoc')))
      } else {
        return callback()
      }
    }
    const validateLastPickupDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_latestPickupDate')))
      } else {
        return callback()
      }
    }
    const validatePrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_price')))
      } else {
        return callback()
      }
    }

    return{
      activeName: 'first',
      directContainerInfoForm:{
        size: '',
        type: '',
        sizeType: '',
        qty: '',
        colorSys: '',
        hasForklift: 'Yes',
        hasLockBox: 'Yes',
        logoRequest: 'Yes',
        pickupLoc: '',
        lastPickupDate: '',
        price: '',
        remark: '',
      },
      directContainerInfoRules: {
        size: [{ required: true, validator: validateSize, trigger: 'blur' }],
        type: [{ required: true, validator: validateType, trigger: 'blur' }],
        qty: [{ required: true, validator: validateQty, trigger: 'blur' }],
        colorSys: [{ required: true, validator: validateColorSys, trigger: 'blur' }],
        pickupLoc: [{ required: true, validator: validatePickupLoc, trigger: 'blur' }],
        lastPickupDate: [{ required: true, validator: validateLastPickupDate, trigger: 'blur' }],
        price: [{ required: true, validator: validatePrice, trigger: 'blur' }],
      },
      collageContainerInfoForm:{
        size: '',
        type: '',
        sizeType: '',
        qty: '',
        pickupLoc: '',
        lastPickupDate: '',
        price: '',
        remark: '',
      },
      collageContainerInfoRules: {
        size: [{ required: true, validator: validateSize, trigger: 'blur' }],
        type: [{ required: true, validator: validateType, trigger: 'blur' }],
        qty: [{ required: true, validator: validateQty, trigger: 'blur' }],
        pickupLoc: [{ required: true, validator: validatePickupLoc, trigger: 'blur' }],
        lastPickupDate: [{ required: true, validator: validateLastPickupDate, trigger: 'blur' }],
        price: [{ required: true, validator: validatePrice, trigger: 'blur' }],
      },
      showTip: false,
      tipSubject: '',
      tipContent: '',
      portNameOptions: [],
      loading: false,
    }
  },
  methods:{
    handleClose() {
        this.showTip = false;
        this.$router.push('/factoryOrderManage')
    },
    async submitFactoryOrder(flag){
      let result;
      if(flag === 'direct'){
        await this.$refs.directContainerInfoForm.validate();
        this.directContainerInfoForm.sizeType = this.directContainerInfoForm.size + this.directContainerInfoForm.type;
        this.directContainerInfoForm.lastPickupDate = this.dateFormat(this.directContainerInfoForm.lastPickupDate);
        const params = {
          ...this.directContainerInfoForm,
          userId: this.getLocalStoreItem('userId'),
          productType: 'Single Order'
        }
        console.log(params);
        result = await this.$axios.post('/bk/tradingFactoryOrder/submitOrder', params);
      }else{
        await this.$refs.collageContainerInfoForm.validate()
        this.collageContainerInfoForm.sizeType = this.collageContainerInfoForm.size + this.collageContainerInfoForm.type;
        this.collageContainerInfoForm.lastPickupDate = this.dateFormat(this.collageContainerInfoForm.lastPickupDate);
        const params = {
          ...this.collageContainerInfoForm,
          userId: this.getLocalStoreItem('userId'),
          productType: 'Group Order'
        }
        console.log(params);
        result = await this.$axios.post('/bk/tradingFactoryOrder/submitOrder', params);
      }

      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }else if(result === 'success'){
        this.directContainerInfoForm = {
          size: '',
          type: '',
          sizeType: '',
          qty: '',
          colorSys: '',
          hasForklift: 'Yes',
          hasLockBox: 'Yes',
          logoRequest: 'Yes',
          pickupLoc: '',
          lastPickupDate: '',
          price: '',
          remark: '',
        }
        this.collageContainerInfoForm = {
          size: '',
          type: '',
          sizeType: '',
          qty: '',
          pickupLoc: '',
          lastPickupDate: '',
          price: '',
          remark: '',
        }
        this.tipSubject = this.$t('t.send_confirm_tip_factoryOrder_subject');
        this.tipContent = this.$t('t.send_confirm_tip_factoryOrder_content');
        this.showTip = true;
      }else{
        this.$message.error(this.$t('t.contact_IT'))
      }
    },
    dateFormat(dateStr){
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;

      const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
      return formattedDate;
    },
    async searchPort(query){
      try {
        const param = {
          portName: query
        }
        this.loading = true;
        const res = await this.$axios.get('/bk/port/searchPort', {
          params: param
        })
        this.loading = false;
        this.portNameOptions = res;
      } catch (e) {
        console.log(e, 'e')
      }
    },
  },
  created() {
    if(localStorage.getItem('lang')){
        this.$i18n.locale = localStorage.getItem('lang');
    }
    this.searchPort('SHA');
  },
  computed: {
      currentLang () {
          return this.$i18n.locale
      }
  }

}
