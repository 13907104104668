//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "spot_space",
  data(){
    const validatesDeparture = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_departureVerification')))
      } else {
        return callback()
      }
    }
    const validatesDestination = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_destinationVerification')))
      } else {
        return callback()
      }
    }
    const validatesContainer = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_containerVerification')))
      } else {
        return callback()
      }
    }
    const validatesQuantity = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_quantityVerification')))
      } else {
        return callback()
      }
    }
    const validatesShippingTerms = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_shippingTermsVerification')))
      } else {
        return callback()
      }
    }
    const validatesCargoReadyDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_cargoReadyDateVerification')))
      } else {
        return callback()
      }
    }
    const validatesCommodity = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_commodityVerification')))
      } else {
        return callback()
      }
    }
    const validatesCargoNetWeight = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_cargoNetWeightVerification')))
      } else {
        return callback()
      }
    }
    const validatesCargoVolume = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_cargoVolumeVerification')))
      } else {
        return callback()
      }
    }
    const validatesAcceptTransfer = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('s.spotSpace_inquiry_form_acceptTransferVerification')))
      } else {
        return callback()
      }
    }

    return{
      searchInfoForm:{
        slotType:'',
        routeName: '',
        departure: '',
        destination: ''
      },
      inquiryFormVisible : false,
      inquiryForm:{
        slotType:'COC',
        isDangerous: 'No',
        departure: '',
        destination: '',
        sizeType: '',
        qty: '',
        shippingTerms : 'CY/CY',
        cargoReadyDate: '',
        commodity: '',
        weight: '',
        volume: '',
        acceptTransit: 'Yes',
        sizeType1: '',
        size1: '',
        type1: '',
        qty1: '',
        sizeType2: '',
        size2: '',
        type2: '',
        qty2: '',
        sizeType3: '',
        size3: '',
        type3: '',
        qty3: '',
        sizeType4: '',
        size4: '',
        type4: '',
        qty4: '',
      },
      showTip: false,
      tipSubject: '',
      tipContent: '',
      inquiryFormRules:{
        departure: [{ required: true, validator: validatesDeparture, trigger: 'blur' }],
        destination: [{ required: true, validator: validatesDestination, trigger: 'blur' }],
        sizeType: [{ required: true, validator: validatesContainer, trigger: 'blur' }],
        // qty: [{ required: true, validator: validatesQuantity, trigger: 'blur' }],
        shippingTerms: [{ required: true, validator: validatesShippingTerms, trigger: 'blur' }],
        cargoReadyDate: [{ required: true, validator: validatesCargoReadyDate, trigger: 'blur' }],
        commodity: [{ required: true, validator: validatesCommodity, trigger: 'blur' }],
        // weight: [{ required: true, validator: validatesCargoNetWeight, trigger: 'blur' }],
        // volume: [{ required: true, validator: validatesCargoVolume, trigger: 'blur' }],
        acceptTransit: [{ required: true, validator: validatesAcceptTransfer, trigger: 'blur' }],
      },
      spotSpaceResourceList: [],
      departureOptions: [],
      destinationOptions: [],
      isEmpty: true,
      currentPage: 1, // 当前页码
      pageSize: 50,// 每页显示的条目数量
      totalItems: 0, // 总条目数
      totalPages: 0,// 总页数
      routeOptions: [],
      offset: 0,
    }
  },
  created () {
    this.searchRoute();
    this.filterDeparturePort('SHA');
    this.filterDestinationPort('Los');
    if(localStorage.getItem('lang')){
      this.$i18n.locale = localStorage.getItem('lang');
    }
    this.searchSpotSpaceResource();
    if(this.$route.params.showInquiryVisible){
      this.inquiryFormVisible = this.$route.params.showInquiryVisible;
    }
  },
  methods:{
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchSpotSpaceResource();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchSpotSpaceResource();
    },
    initPaging(){
      this.currentPage =  1;// 当前页码
    },
    async searchSpotSpaceResource () {
      try {
        this.offset = (this.currentPage - 1) * this.pageSize;
        const param = {
          ...this.searchInfoForm,
          offset: this.offset,
          pageSize: this.pageSize
        }
        const res = await this.$axios.get('/bk/spot/search', {
          params: param
        })
        this.spotSpaceResourceList = [];
        if(res.resultList.length > 0){
          this.spotSpaceResourceList = res.resultList;
        }
        this.totalItems = res.total;
        this.totalPages=  (this.totalItems  / this.pageSize) + this.totalItems < this.pageSize ? 1:0;
        this.isEmpty = this.spotSpaceResourceList.length === 0;

      } catch (e) {
        console.log(e, 'e')
      }
    },
    searchSpotSpace(){
      this.initPaging();
      this.searchSpotSpaceResource();
    },
    openInquiryDialog(){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      this.inquiryFormVisible = true;
    },
    async spotSpaceInquiry(){
      try {
        await this.$refs.inquiryForm.validate()

        if((this.inquiryForm.size1 === '' && this.inquiryForm.size2 === '' && this.inquiryForm.size3 === '' && this.inquiryForm.size4 === '') &&
          (this.inquiryForm.type1 === '' && this.inquiryForm.type2 === '' && this.inquiryForm.type3 === '' && this.inquiryForm.type4 === '')
        ){
          this.$message.error(this.$t('s.spotSpace_inquiry_form_containerTypeVerification'))
            return
        }
        if(
          ((this.inquiryForm.size1 === '' && this.inquiryForm.type1 !== '') || (this.inquiryForm.size1 !== '' && this.inquiryForm.type1 === '')) ||
          ((this.inquiryForm.size2 === '' && this.inquiryForm.type2 !== '') || (this.inquiryForm.size2 !== '' && this.inquiryForm.type2 === '')) ||
          ((this.inquiryForm.size3 === '' && this.inquiryForm.type3 !== '') || (this.inquiryForm.size3 !== '' && this.inquiryForm.type3 === '')) ||
          ((this.inquiryForm.size4 === '' && this.inquiryForm.type4 !== '') || (this.inquiryForm.size4 !== '' && this.inquiryForm.type4 === ''))
        ){
          this.$message.error(this.$t('s.spotSpace_inquiry_form_containerSizeVerification'))
          return
        }
        const params = {
          ...this.inquiryForm,
          userId: this.getLocalStoreItem('userId'),
          acceptTransit: this.inquiryForm.acceptTransit === 'Yes',
          dangerous: this.inquiryForm.isDangerous === 'Yes',
          sizeType1: this.inquiryForm.size1 + this.inquiryForm.type1,
          sizeType2: this.inquiryForm.size2 + this.inquiryForm.type2,
          sizeType3: this.inquiryForm.size3 + this.inquiryForm.type3,
          sizeType4: this.inquiryForm.size4 + this.inquiryForm.type4,
        }
        const result = await this.$axios.post('/bk/spotConsult/consultSpot', params);
        if(result === 401){
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
            path:'/login'
          })
        }else if(result === 'success'){
          this.inquiryForm= {
            slotType:'COC',
            isDangerous: 'No',
            departure: '',
            destination: '',
            sizeType: '',
            qty: '',
            shippingTerms : 'CY/CY',
            cargoReadyDate: '',
            commodity: '',
            weight: '',
            volume: '',
            acceptTransit: 'Yes',
            sizeType1: '',
            size1: '',
            type1: '',
            qty1: '',
            sizeType2: '',
            size2: '',
            type2: '',
            qty2: '',
            sizeType3: '',
            size3: '',
            type3: '',
            qty3: '',
            sizeType4: '',
            size4: '',
            type4: '',
            qty4: '',
          }
          this.inquiryFormVisible = false;
          this.tipSubject = this.$t('s.spotSpace_send_confirm_tip_inquiry_subject');
          this.tipContent = this.$t('s.spotSpace_send_confirm_tip_inquiry_content');
          this.showTip = true;
        }else{
          this.$message.error(this.$t('t.contact_IT'))
        }
      }catch (e) {
        console.log(e, 'e')
      }
    },
    dateFormat(dateStr){
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;

      const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
      return formattedDate;
    },
    handleClose() {
      this.showTip = false;
      this.$router.push('/spotSpaceInquiryOrder')
    },
    purchaseClicked(item){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      try {
          let url = this.baseURL + '/#/spotSpacePurchase/' + item.resourceNo;
          window.open(url);
      } catch (e) {
        console.log(e, 'e')
      }
    },
    detailClicked(item){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      try {
          let url = this.baseURL + '/#/spotSpaceResourceDetail/' + item.resourceNo;
          window.open(url);
      } catch (e) {
        console.log(e, 'e')
      }
    },
    async searchPort(query, isDeparture){
      try {
        const param = {
          portName: query
        }
        this.loading = true;
        const res = await this.$axios.get('/bk/port/searchPort', {
          params: param
        })
        this.loading = false;
        let temp =[];
        if(isDeparture){
          this.departureOptions = res;
        }else{
          this.destinationOptions = res;
        }

      } catch (e) {
        console.log(e, 'e')
      }
    },
    filterDeparturePort(query){
      this.searchPort(query, true);
    },
    filterDestinationPort(query){
      this.searchPort(query, false);
    },
    async searchRoute () {
        try {
            this.loading = true
            const res = await this.$axios.get('/bk/port/filterRoute', {
                params: {}
            })
            this.loading = false
            if (res) {
                this.routeOptions = res
            }
        } catch (e) {
            console.log(e, 'e')
        }
    },
  },
  watch: {
  }
}
