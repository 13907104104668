//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  data () {
    const validateEnquirySize = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_size')))
      } else {
        return callback()
      }
    }
    const validateEnquiryType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_type')))
      } else {
        return callback()
      }
    }
    const validateEnquiryContainerStatus = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_condition')))
      } else {
        return callback()
      }
    }
    const validateEnquiryRequestQty = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_qty')))
      } else {
        return callback()
      }
    }
    const validateEnquiryPickupLoc = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_pickupLoc')))
      } else {
        return callback()
      }
    }
    const validateEnquiryLastPickupDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_latestPickupDate')))
      } else {
        return callback()
      }
    }

    const validateEntrustSize = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_size')))
      } else {
        return callback()
      }
    }
    const validateEntrustType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_type')))
      } else {
        return callback()
      }
    }
    const validateEntrustContainerStatus = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_condition')))
      } else {
        return callback()
      }
    }
    const validateEntrustQty = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_qty')))
      } else {
        return callback()
      }
    }
    const validateEntrustPickupLoc = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_pickupLoc')))
      } else {
        return callback()
      }
    }
    const validateEntrustValidDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_validate')))
      } else {
        return callback()
      }
    }
    const validateEntrustproductionYear = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_year')))
      } else {
        return callback()
      }
    }
    const validateEntrustPrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_price')))
      } else {
        return callback()
      }
    }
    const validateEntrustMinPrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_minPrice')))
      } else {
        return callback()
      }
    }
    return {
        searchInfoForm:{
            containerType: '',
            containerStatus: 'Brand New',
            pickupLoc: '',
            size: '',
            type: ''
        },
        currentNew: true,
        currentSecNew: false,
        currentSecHand: false,
        currentAsis:false,
        tradingResourceList: [],
        isEmpty: true,
        showConsultDialog: false,
        consultForm: {
            sizeType: '',
            requestQty: '',
            pickupLoc: '',
            containerStatus: '',
            lastPickupDate: '',
            size: '',
            type: ''
        },
        enquiryRules: {
          size: [{ required: true, validator: validateEnquirySize, trigger: 'blur' }],
          type: [{ required: true, validator: validateEnquiryType, trigger: 'blur' }],
          requestQty: [{ required: true, validator: validateEnquiryRequestQty, trigger: 'blur' }],
          containerStatus: [{ required: true, validator: validateEnquiryContainerStatus, trigger: 'blur' }],
          pickupLoc: [{ required: true, validator: validateEnquiryPickupLoc, trigger: 'blur' }],
          lastPickupDate: [{ required: true, validator: validateEnquiryLastPickupDate, trigger: 'blur' }],
        },
        showEntrustDialog: false,
        entrustForm: {
            sizeType: '',
            containerStatus: '',
            qty: '',
            productionYear: '',
            pickupLoc: '',
            price: '',
            colorSys: '',
            hasLockBox: false,
            hasForklift: false,
            minPrice: '',
            validDate: '',
            containerPhotoContent: '',
            size: '',
            type: ''
        },
        entrustRules: {
          size: [{ required: true, validator: validateEntrustSize, trigger: 'blur' }],
          type: [{ required: true, validator: validateEntrustType, trigger: 'blur' }],
          qty: [{ required: true, validator: validateEntrustQty, trigger: 'blur' }],
          containerStatus: [{ required: true, validator: validateEntrustContainerStatus, trigger: 'blur' }],
          productionYear: [{ required: true, validator: validateEntrustproductionYear, trigger: 'blur' }],
          pickupLoc: [{ required: true, validator: validateEntrustPickupLoc, trigger: 'blur' }],
          price: [{ required: true, validator: validateEntrustPrice, trigger: 'blur' }],
          minPrice: [{ required: true, validator: validateEntrustMinPrice, trigger: 'blur' }],
          validDate: [{ required: true, validator: validateEntrustValidDate, trigger: 'blur' }],
        },
        previewImg: false,
        dialogImageUrl: '',
        fileList: [],
        showTip: false,
        showFunc: '1',
        tipSubject: '',
        tipContent: '',
        portNameOptions: [],
        loading: false,
        totalItems: 0,// 总条目数
        currentPage: 1, // 当前页码
        pageSize: 16,// 每页显示的条目数量
        offset: 0,
    }
  },
  created () {
      if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
      this.searchTradingResource(true);
      this.searchPort('SHA');
  },
  methods: {
      async onRefresh() {
        this.currentPage = 1;
        await this.searchTradingResource(true);
        this.$nextTick(() => {
          this.$refs.my_scroller.finishPullToRefresh();// 关闭底部加载转圈
        })
      },
      // 触底加载
      async onScroll() {
        this.currentPage++;
        await this.searchTradingResource(false);
        this.$nextTick(() => {
          this.$refs.my_scroller.finishInfinite(true); // 关闭底部加载转圈
        })
      },
      handleClose() {
          this.showTip = false;
          if(this.showFunc === '1'){
              this.$router.push('/containerInquiry')
          }else if(this.showFunc === '2'){
              this.$router.push('/containerDelegation')
          }
      },
      handleNew(){
          this.currentNew = true;
          this.currentSecNew = false;
          this.currentSecHand = false;
          this.currentAsis = false;
      },
      handleSecNew(){
          this.currentNew = false;
          this.currentSecNew = true;
          this.currentSecHand = false;
          this.currentAsis = false;
      },
      handleSecHand(){
          this.currentNew = false;
          this.currentSecNew = false;
          this.currentSecHand = true;
          this.currentAsis = false;
      },
      handleCurrent(){
        this.currentNew = false;
        this.currentSecNew = false;
        this.currentSecHand = false;
        this.currentAsis = true;
      },
      changeToNew(){
          this.handleNew();
          this.searchInfoForm.containerStatus = 'Brand New';
          this.searchTradingResource(true);
      },
      changeToSecNew(){
          this.handleSecNew();
          this.searchInfoForm.containerStatus = 'IICL';
          this.searchTradingResource(true);
      },
      changeToSecHand(){
          this.handleSecHand();
          this.searchInfoForm.containerStatus = 'CW';
          this.searchTradingResource(true);
      },
      changeToCurrent(){
        this.handleCurrent();
        this.searchInfoForm.containerStatus = 'AS-IS';
        this.searchTradingResource(true);
      },
      search(){
          this.searchTradingResource(true);
          if(this.searchInfoForm.containerStatus === 'Brand New'){
              this.handleNew();
          }
          if(this.searchInfoForm.containerStatus === 'IICL'){
              this.handleSecNew();
          }
          if(this.searchInfoForm.containerStatus === 'CW'){
              this.handleSecHand();
          }
          if(this.searchInfoForm.containerStatus === 'AS-IS'){
              this.handleCurrent();
          }
      },
      async searchTradingResource (isRefresh) {
          try {
              if(isRefresh){
                this.offset = 0;
              }else{
                this.offset = (this.currentPage - 1) * this.pageSize;
              }

              this.searchInfoForm.containerType = this.searchInfoForm.size + this.searchInfoForm.type;

              const param = {
                  ...this.searchInfoForm,
                  offset: this.offset,
                  pageSize: this.pageSize
              }
              console.log(param);
              const res = await this.$axios.get('/bk/trading/search', {
                  params: param
              })
              let temp =[];

              if(isRefresh){
                this.tradingResourceList = [];
                for(let i = 0; i < res.resultList.length; i ++){
                  res.resultList[i].containerPhotoUrl1 = !res.resultList[i].containerPhotoUrl1 ? this.baseURL + '/resource/img/container_03.png' : res.resultList[i].containerPhotoUrl1;
                  res.resultList[i].containerPhotoUrl2 = !res.resultList[i].containerPhotoUrl2 ? this.baseURL + '/resource/img/container_03.png' : res.resultList[i].containerPhotoUrl2;
                  res.resultList[i].containerPhotoUrl3 = !res.resultList[i].containerPhotoUrl3 ? this.baseURL + '/resource/img/container_03.png' : res.resultList[i].containerPhotoUrl3;
                  if(i % 2 === 0){
                    if(temp.length > 0){
                      this.tradingResourceList.push(temp);
                    }
                    temp = [];
                  }
                  temp.push(res.resultList[i]);
                }
                this.tradingResourceList.push(temp);

              }else{

                if(res.resultList.length > 0){
                  for(let i = 0; i < res.resultList.length; i ++){
                    res.resultList[i].containerPhotoUrl1 = !res.resultList[i].containerPhotoUrl1 ? this.baseURL + '/resource/img/container_03.png' : res.resultList[i].containerPhotoUrl1;
                    res.resultList[i].containerPhotoUrl2 = !res.resultList[i].containerPhotoUrl2 ? this.baseURL + '/resource/img/container_03.png' : res.resultList[i].containerPhotoUrl2;
                    res.resultList[i].containerPhotoUrl3 = !res.resultList[i].containerPhotoUrl3 ? this.baseURL + '/resource/img/container_03.png' : res.resultList[i].containerPhotoUrl3;
                    if(i % 2 === 0){
                      if(temp.length > 0){
                        this.tradingResourceList.push(temp);
                      }
                      temp = [];
                    }
                    temp.push(res.resultList[i]);
                  }
                  this.tradingResourceList.push(temp);
                }

              }

              this.isEmpty = this.tradingResourceList.length === 0;
              this.totalItems = res.total;
              console.log(this.totalItems+'totalItems')
              console.log( this.tradingResourceList, ' this.tradingResourceList')
          } catch (e) {
              console.log(e, 'e')
          }
      },
      async consultContainer(){
        await this.$refs.consultForm.validate()

        this.consultForm.sizeType = this.consultForm.size + this.consultForm.type;

        const params = {
            ...this.consultForm,
            userId: this.getLocalStoreItem('userId'),
            lastPickupDate:this.dateFormat(this.consultForm.lastPickupDate)
        }
        const result = await this.$axios.post('/bk/tradingConsult/consultContainer', params);
        if(result === 401){
            localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
            this.$router.push({
                path:'/login'
            })
        }else if(result === 'success'){
            this.consultForm= {
                sizeType: '',
                requestQty: '',
                pickupLoc: '',
                containerStatus: '',
                lastPickupDate: ''
            },
            this.showConsultDialog = false;
            this.tipSubject = this.$t('t.send_confirm_tip_inquiry_subject');
            this.tipContent = this.$t('t.send_confirm_tip_inquiry_content');
            this.showTip = true;
            this.showFunc = '1'; //询价
        }else{
            this.$message.error(this.$t('t.contact_IT'))
        }
    },
      dateFormat(dateStr){
          const date = new Date(dateStr);
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();

          const formattedMonth = month < 10 ? `0${month}` : month;
          const formattedDay = day < 10 ? `0${day}` : day;

          const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
          return formattedDate;
      },
      async addEntrustResource(){
        await this.$refs.entrustForm.validate()
        this.entrustForm.sizeType = this.entrustForm.size + this.entrustForm.type;
        this.entrustForm.hasLockBox= this.entrustForm.hasLockBox ? 'Yes': 'No' ;
        this.entrustForm.hasForklift= this.entrustForm.hasForklift ? 'Yes': 'No';
        const params = {
            ...this.entrustForm,
            userId: this.getLocalStoreItem('userId'),
            validDate:this.dateFormat(this.entrustForm.validDate)
        }
        const result = await this.$axios.post('/bk/tradingEntrust/entrustSell', params);
        if(result === 401){
            localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
            this.$router.push({
                path:'/login'
            })
        }else if(result === 'success'){
            this.entrustForm = {
                sizeType: '',
                containerStatus: '',
                qty: '',
                productionYear: '',
                pickupLoc: '',
                price: '',
                minPrice: '',
                validDate: '',
                containerPhotoContent: ''
            }
            this.showEntrustDialog = false;
            this.tipSubject = this.$t('t.send_confirm_tip_entrust_subject');
            this.tipContent = this.$t('t.send_confirm_tip_entrust_content');
            this.showTip = true;
            this.showFunc = '2'; //委托
        }else{
            this.$message.error(this.$t('t.contact_IT'))
        }
    },
      handlePictureCardPreview (file) {
          this.dialogImageUrl = file.url
          this.dialogVisible = true
      },
      getFile (file, fileList, num) {
          this.getBase64(file.raw).then(res => {
              const params = res
              console.log(params, 'params')
              this.proofImage = params
              if (num === 1) {
                  this.entrustForm.containerPhotoContent = this.proofImage
              }

          })
      },
      getBase64 (file) {
          return new Promise(function (resolve, reject) {
              const reader = new FileReader()
              let imgResult = ''
              reader.readAsDataURL(file)
              reader.onload = function () {
                  imgResult = reader.result
              }
              reader.onerror = function (error) {
                  reject(error)
              }
              reader.onloadend = function () {
                  resolve(imgResult)
              }
          })
      },
      handleUploadRemove (file, fileList, num) {
          this.proofImage = ''
          if (num === 1) {
              this.entrustForm.containerPhotoContent = this.proofImage
          }
      },
      jumpToPurchase (item) {
          if(!this.getLocalStoreItem('token')){
            localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
            this.$router.push({
              path:'/login'
            })
            return ;
          }
          try {
              this.$router.push({
                  path: '/containerPurchase/' + item.resourceNo
              });
          } catch (e) {
              console.log(e, 'e')
          }

      },
      toDetail(item){
          if(!this.getLocalStoreItem('token')){
            localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
            this.$router.push({
              path:'/login'
            })
            return ;
          }
          try {
              this.$router.push({
                  path: '/containerDetail/' + item.resourceNo
              });
          } catch (e) {
              console.log(e, 'e')
          }
      },
      openConsultDialog(){
          if(!this.getLocalStoreItem('token')){
              localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
              this.$router.push({
                  path:'/login'
              })
              return ;
          }
          this.showConsultDialog = true;
      },
      openEntrustDialog(){
          if(!this.getLocalStoreItem('token')){
              localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
              this.$router.push({
                  path:'/login'
              })
              return ;
          }
          this.showEntrustDialog = true;
      },
      openFactoryDialog(){
        if(!this.getLocalStoreItem('token')){
          localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
          this.$router.push({
            path:'/login'
          })
          return ;
        }
        this.$router.push({
          path:'/containerFactoryPurchase'
        })
      },
    openDepotDialog(){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      this.$router.push({
        path:'/containerDepot'
      })
    },
    openDemandDialog(){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      this.$router.push({
        path:'/containerDemand'
      })
    },
      async searchPort(query){
        try {
          const param = {
            portName: query
          }
          this.loading = true;
          const res = await this.$axios.get('/bk/port/searchPort', {
            params: param
          })
          this.loading = false;
          this.portNameOptions = res;
        } catch (e) {
          console.log(e, 'e')
        }
      },
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    },
  },
  watch: {
    currentLang: {
      handler (locale) {
        this.$nextTick(() => {
          if(this.$refs.consultForm){
            this.$refs.consultForm.fields.forEach(item => {
              if (item.validateState === 'error') {
                this.$refs.consultForm.validateField(item.labelFor)
              }
            })
          }
          if(this.$refs.entrustForm){
            this.$refs.entrustForm.fields.forEach(item => {
              if (item.validateState === 'error') {
                this.$refs.entrustForm.validateField(item.labelFor)
              }
            })
          }
        })
      },
      immediate: true
    }
  },
}
