//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "container_trading",
  data() {
    const validateEnquirySize = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_size')))
      } else {
        return callback()
      }
    }
    const validateEnquiryType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_type')))
      } else {
        return callback()
      }
    }
    const validateEnquiryContainerStatus = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_condition')))
      } else {
        return callback()
      }
    }
    const validateEnquiryRequestQty = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_qty')))
      } else {
        return callback()
      }
    }
    const validateEnquiryPickupLoc = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_pickupLoc')))
      } else {
        return callback()
      }
    }
    const validateEnquiryLastPickupDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_latestPickupDate')))
      } else {
        return callback()
      }
    }
    const validateEntrustSize = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_size')))
      } else {
        return callback()
      }
    }
    const validateEntrustType = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_type')))
      } else {
        return callback()
      }
    }
    const validateEntrustContainerStatus = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_condition')))
      } else {
        return callback()
      }
    }
    const validateEntrustQty = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_qty')))
      } else {
        return callback()
      }
    }
    const validateEntrustPickupLoc = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_pickupLoc')))
      } else {
        return callback()
      }
    }
    const validateEntrustproductionYear = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_year')))
      } else {
        return callback()
      }
    }
    const validateEntrustPrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_price')))
      } else {
        return callback()
      }
    }
    const validateEntrustMinPrice = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('t.form_validate_error_tip_minPrice')))
      } else {
        return callback()
      }
    }
    return {
      activeName: 'newBox',
      tabs: [
        {
          title: this.$t('t.resource_container_BrandNew'),
          value: "newBox",
          id: 1
        },
        {
          title: this.$t('t.resource_container_IICL'),
          value: "secondaryBox",
          id: 2

        },
        {
          title: this.$t('t.resource_container_CW'),
          value: "usedBox",
          id: 3
        },
        {
          title: this.$t('t.resource_container_ASIS'),
          value: "currentBox",
          id: 4
        }
      ],
      searchInfoForm:{
        containerType: '',
        containerStatus: '',
        pickupLoc: '',
        size: '',
        type: ''
      },
      portNameOptions: [],
      loading: false,
      num: 1,
      dialogFormVisible: false,
      consultForm: {
        sizeType: '',
        requestQty: '',
        pickupLoc: '',
        containerStatus: '',
        lastPickupDate: '',
        size: '',
        type: ''
      },
      rules: {
        size: [{ required: true, validator: validateEnquirySize, trigger: 'blur' }],
        type: [{ required: true, validator: validateEnquiryType, trigger: 'blur' }],
        requestQty: [{ required: true, validator: validateEnquiryRequestQty, trigger: 'blur' }],
        containerStatus: [{ required: true, validator: validateEnquiryContainerStatus, trigger: 'blur' }],
        pickupLoc: [{ required: true, validator: validateEnquiryPickupLoc, trigger: 'blur' }],
        lastPickupDate: [{ required: true, validator: validateEnquiryLastPickupDate, trigger: 'blur' }],
      },
      value1: '',
      tradingResourceList: [],
      showTip: false,
      tipSubject: '',
      tipContent: '',
      isEmpty: true,
      showEntrustDialog: false,
      entrustForm: {
        sizeType: '',
        containerStatus: '',
        qty: '',
        productionYear: '',
        pickupLoc: '',
        price: '',
        colorSys: '',
        hasLockBox: false,
        hasForklift: false,
        minPrice: '',
        validDate: '',
        containerPhotoContent: '',
        size: '',
        type: ''
      },
      entrustRules: {
        size: [{ required: true, validator: validateEntrustSize, trigger: 'blur' }],
        type: [{ required: true, validator: validateEntrustType, trigger: 'blur' }],
        qty: [{ required: true, validator: validateEntrustQty, trigger: 'blur' }],
        containerStatus: [{ required: true, validator: validateEntrustContainerStatus, trigger: 'blur' }],
        productionYear: [{ required: true, validator: validateEntrustproductionYear, trigger: 'blur' }],
        pickupLoc: [{ required: true, validator: validateEntrustPickupLoc, trigger: 'blur' }],
        price: [{ required: true, validator: validateEntrustPrice, trigger: 'blur' }],
        minPrice: [{ required: true, validator: validateEntrustMinPrice, trigger: 'blur' }],
        // validDate: [{ required: true, validator: validateEntrustValidDate, trigger: 'blur' }],
      },
      showSetting: false,
      dialogImageUrl: '',
      fileList: [],
      dialogVisible: false,
      currentTip: '',
      currentPage: 1, // 当前页码
      pageSize: 40,// 每页显示的条目数量
      totalItems: 0, // 总条目数
      totalPages: 0,// 总页数
    };
  },
  created () {
    if(localStorage.getItem('lang')){
          this.$i18n.locale = localStorage.getItem('lang');
      }
    this.searchTradingResource();

    this.searchPort('SHA');
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchTradingResource();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchTradingResource();
    },
    initPaging(){
      this.currentPage =  1;// 当前页码
    },
    handleClose() {
      if(this.currentTip === 'consult'){
        this.$router.push('/containerInquiry')
      }
      if(this.currentTip === 'entrust'){
        this.$router.push('/containerDelegation')
      }
      this.showTip = false;

    },
    handleClick(tab, event) {

      this.activeName = tab.name;

      if(tab.name === 'newBox'){
        this.searchInfoForm.containerStatus = 'Brand New';
      }
      if(tab.name === 'secondaryBox'){
        this.searchInfoForm.containerStatus = 'IICL';
      }
      if(tab.name === 'usedBox'){
        this.searchInfoForm.containerStatus = 'CW';
      }
      if(tab.name === 'currentBox'){
        this.searchInfoForm.containerStatus = 'AS-IS';
      }
      this.initPaging();
      this.searchTradingResource();
    },
    jumpToPurchase (item) {
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
        let url = this.baseURL + '/#/containerPurchase/' + item.resourceNo;
        window.open(url);
    },
    jumpToDetail(item){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      try {
        let url = this.baseURL + '/#/containerDetail/' + item.resourceNo;
        window.open(url);
      } catch (e) {
        console.log(e, 'e')
      }
    },
    search(){
      this.initPaging();
      if(this.searchInfoForm.containerStatus === 'Brand New'){
        this.activeName = 'newBox';
      }
      if(this.searchInfoForm.containerStatus === 'IICL'){
        this.activeName = 'secondaryBox';
      }
      if(this.searchInfoForm.containerStatus === 'CW'){
        this.activeName = 'usedBox';
      }
      if(this.searchInfoForm.containerStatus === 'AS-IS'){
        this.activeName = 'currentBox';
      }
      this.searchTradingResource();
    },
    async searchTradingResource () {
      try {
        if(!this.searchInfoForm.containerStatus){
          this.searchInfoForm.containerStatus = 'Brand New';
          this.activeName = 'newBox';
        }

        this.searchInfoForm.containerType = this.searchInfoForm.size + this.searchInfoForm.type;
        this.offset = (this.currentPage - 1) * this.pageSize;
        const param = {
          ...this.searchInfoForm,
          offset: this.offset,
          pageSize: this.pageSize
        }
        this.loading = true;
        const res = await this.$axios.get('/bk/trading/search', {
          params: param
        })
        this.loading = false;
        let temp =[];
        this.tradingResourceList = [];
        if(res.resultList.length > 0){
          for(let i = 0; i < res.resultList.length; i ++){
            res.resultList[i].containerPhotoUrl1 = !res.resultList[i].containerPhotoUrl1 ? this.baseURL + '/resource/img/container_03.png' : res.resultList[i].containerPhotoUrl1;
            res.resultList[i].containerPhotoUrl2 = !res.resultList[i].containerPhotoUrl2 ? this.baseURL + '/resource/img/container_03.png' : res.resultList[i].containerPhotoUrl2;
            res.resultList[i].containerPhotoUrl3 = !res.resultList[i].containerPhotoUrl3 ? this.baseURL + '/resource/img/container_03.png' : res.resultList[i].containerPhotoUrl3;
            if(i % 4 === 0){
              if(temp.length > 0){
                this.tradingResourceList.push(temp);
              }
              temp = [];
            }
            temp.push(res.resultList[i]);
          }
          this.tradingResourceList.push(temp);
        }
        this.totalItems = res.total;
        this.totalPages=  (this.totalItems  / this.pageSize) + this.totalItems < this.pageSize ? 1:0;
        this.isEmpty = this.tradingResourceList.length === 0;
      } catch (e) {
        console.log(e, 'e')
      }
    },
    refrenshTradingResource () {
      this.searchTradingResource()
    },
    dateFormat(dateStr){
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;

      const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
      return formattedDate;
    },
    async consultContainer(){
      try {
        await this.$refs.consultForm.validate()
        this.consultForm.sizeType = this.consultForm.size + this.consultForm.type;
        const params = {
          ...this.consultForm,
          userId: this.getLocalStoreItem('userId'),
          lastPickupDate:this.dateFormat(this.consultForm.lastPickupDate)
        }

        const result = await this.$axios.post('/bk/tradingConsult/consultContainer', params);
          if(result === 401){
              localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
              this.$router.push({
                  path:'/login'
              })
          }else if(result === 'success'){
              this.consultForm= {
                  sizeType: '',
                  requestQty: '',
                  pickupLoc: '',
                  containerStatus: '',
                  lastPickupDate: ''
              },
              this.dialogFormVisible = false;
              this.tipSubject = this.$t('t.send_confirm_tip_inquiry_subject');
              this.tipContent = this.$t('t.send_confirm_tip_inquiry_content');
              this.currentTip = 'consult';
              this.showTip = true;
          }else{
              this.$message.error(this.$t('t.contact_IT'))
          }
      } catch (e) {
        console.log(e, 'e')
      }
    },
    openConsultDialog(){
        if(!this.getLocalStoreItem('token')){
            localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
            this.$router.push({
                path:'/login'
            })
            return ;
        }
        this.dialogFormVisible = true;
    },
    factoryPurchase(){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      this.$router.push({
        path:'/containerFactoryPurchase'
      })
    },
    globalDepot(){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      this.$router.push({
        path:'/containerDepot'
      })
    },
    entrustSales(){
      if(this.getLocalStoreItem("username")){
        this.showEntrustDialog = true;
      }else {
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }
    },
    globalDemand(){
      if(!this.getLocalStoreItem('token')){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
        return ;
      }
      this.$router.push({
        path:'/containerDemand'
      })
    },
    async addEntrustResprce(){
      await this.$refs.entrustForm.validate()

      this.entrustForm.sizeType = this.entrustForm.size + this.entrustForm.type;
      this.entrustForm.hasLockBox= this.entrustForm.hasLockBox ? 'Yes': 'No' ;
      this.entrustForm.hasForklift= this.entrustForm.hasForklift ? 'Yes': 'No';
      const params = {
        ...this.entrustForm,
        userId: this.getLocalStoreItem('userId'),
        validDate:this.dateFormat(this.entrustForm.validDate)
      }

      const result = await this.$axios.post('/bk/tradingEntrust/entrustSell', params);
      if(result === 401){
        localStorage.setItem("prePath", location.hash.split('#').length > 1 ? location.hash.split('#')[1] : '');
        this.$router.push({
          path:'/login'
        })
      }else if(result === 'success'){
        this.entrustForm = {
          sizeType: '',
          containerStatus: '',
          qty: '',
          productionYear: '',
          pickupLoc: '',
          price: '',
          minPrice: '',
          validDate: '',
          containerPhotoContent: ''
        }
        this.showEntrustDialog = false;
        this.tipSubject = this.$t('t.send_confirm_tip_entrust_subject');
        this.tipContent = this.$t('t.send_confirm_tip_entrust_content');
        this.currentTip = 'entrust'
        this.showTip = true;
      }else{
        this.$message.error(this.$t('t.contact_IT'))
      }
    },
    toSaleService(){
      this.$router.push('/contractCustomerService')
    },
    clickSetting(){
      this.showSetting = !this.showSetting;
    },
    closeSetting(){
      setTimeout(()=>{
        this.showSetting = false;
      }, 300)
    },
    updateMenu(){
        this.tabs = [
            {
                title: this.$t('t.resource_container_BrandNew'),
                value: "newBox",
                id: 1
            },
            {
                title: this.$t('t.resource_container_IICL'),
                value: "secondaryBox",
                id: 2

            },
            {
                title: this.$t('t.resource_container_CW'),
                value: "usedBox",
                id: 3
            },
            {
                title: this.$t('t.resource_container_ASIS'),
                value: "currentBox",
                id: 4
            }
        ];
    },
    getFile (file, fileList, num) {
      this.getBase64(file.raw).then(res => {
        const params = res
        this.proofImage = params
        if (num === 1) {
          this.entrustForm.containerPhotoContent = this.proofImage
        }

      })
    },
    getBase64 (file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },
    handleUploadRemove (file, fileList, num) {
      this.proofImage = ''
      if (num === 1) {
        this.entrustForm.containerPhotoContent = this.proofImage
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    navigatorPage (path) {
      let splits = window.location.href.split('#');
      if (path && splits.length >1 && splits[1] !== path ) {
        this.$router.push(path)
        this.showSetting = false
      }
    },
    async searchPort(query){
      try {
        const param = {
          portName: query
        }
        this.loading = true;
        const res = await this.$axios.get('/bk/port/searchPort', {
          params: param
        })
        this.loading = false;
        this.portNameOptions = res;
      } catch (e) {
        console.log(e, 'e')
      }
    },
  },
  computed: {
    currentLang () {
      return this.$i18n.locale
    },
    showLoginIcon () {
      return !localStorage.getItem('token');
    }
  },
  watch: {
    currentLang: {
      handler (locale) {
        this.$nextTick(() => {
          this.updateMenu();
          if(this.$refs.consultForm){
            this.$refs.consultForm.fields.forEach(item => {
              if (item.validateState === 'error') {
                this.$refs.consultForm.validateField(item.labelFor)
              }
            })
          }
        })
      },
      immediate: true
    }
  },

};

